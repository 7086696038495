"use client";

import { useCallback } from "react";
import type { Session } from "next-auth";
import Carousel, { type ResponsiveType } from "react-multi-carousel";

import type { BlogArticle } from "@/types";
import { errorIntoString } from "@/lib/utils";
import {
	useGetSavedBlogsQuery,
	useSetSavedBlogsMutation,
} from "@/features/saved/savedApiInjections";

import { useToast } from "@/components/ui/use-toast";
import ArticleCard from "@/components/cards/ArticleCard";
import { CustomButtonGroup } from "@/components/carousels";

import "react-multi-carousel/lib/styles.css";

const responsive: ResponsiveType = {
	desktop: {
		breakpoint: { min: 1536, max: 3000 },
		items: 4,
	},
	laptopLarge: {
		breakpoint: { min: 1280, max: 1536 },
		items: 3,
	},
	laptopMedium: {
		breakpoint: { min: 1200, max: 1280 },
		items: 3,
		partialVisibilityGutter: 0,
	},
	laptop: {
		breakpoint: { min: 1024, max: 1199 },
		items: 2,
		partialVisibilityGutter: 75,
	},
	tabletLarge: {
		breakpoint: { min: 930, max: 1023 },
		items: 2,
		partialVisibilityGutter: 50,
	},
	tabletMedium: {
		breakpoint: { min: 880, max: 929 },
		items: 2,
		partialVisibilityGutter: 25,
	},
	tablet: {
		breakpoint: { min: 800, max: 879 },
		items: 2,
		partialVisibilityGutter: 0,
	},
	tabletSmall: {
		breakpoint: { min: 768, max: 799 },
		items: 1,
		partialVisibilityGutter: 300,
	},
	mobileLarge: {
		breakpoint: { min: 690, max: 767 },
		items: 1,
		partialVisibilityGutter: 250,
	},
	mobileMedium: {
		breakpoint: { min: 640, max: 689 },
		items: 1,
		partialVisibilityGutter: 200,
	},
	mobile: {
		breakpoint: { min: 0, max: 639 },
		items: 1,
	},
};

type Props = {
	session: Session | null;
	articles: BlogArticle[];
};

export default function ArticlesCarousel({ session, articles }: Props) {
	const { toast } = useToast();
	const { data, isLoading } = useGetSavedBlogsQuery(session?.user.id ?? "", {
		skip: !session,
	});
	const [setFav, { isLoading: isFavoriteUpdating }] = useSetSavedBlogsMutation();

	const handleFavoriteClick = useCallback(
		async (article: BlogArticle) => {
			if (!session || data === undefined) return;
			const isFavorite = data.blogIds.find((x) => x._id === article._id);

			if (isFavorite) {
				const resp = await setFav({
					userId: session.user.id,
					blogIds: data.blogIds.filter((x) => x._id !== article._id).map((x) => x._id),
				});

				if ("error" in resp) {
					const ex = errorIntoString(resp.error);
					toast({
						variant: "destructive",
						title: `Failed to remove ${article.title} from saved`,
						description: ex,
					});

					return;
				}

				toast({ title: `${article.title} removed from saved` });
				return;
			}

			const resp = await setFav({
				userId: session.user.id,
				blogIds: [...data.blogIds.map((id) => id._id), article._id],
			});

			if ("error" in resp) {
				const ex = errorIntoString(resp.error);
				toast({
					variant: "destructive",
					title: `Failed to add ${article.title} to saved`,
					description: ex,
				});
				return;
			}

			toast({ title: `${article.title} saved` });
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[data],
	);

	return (
		<div className="relative grid grid-rows-[26rem_2rem]">
			<Carousel
				responsive={responsive}
				ssr
				partialVisible
				arrows={false}
				showDots={false}
				customButtonGroup={<CustomButtonGroup itemLength={articles.length} infinite />}
				renderButtonGroupOutside
				infinite
				keyBoardControl
				autoPlay
				autoPlaySpeed={5000}
				transitionDuration={500}
				customTransition="transform 500ms ease-in-out"
				itemClass="px-2 sm:px-0"
				className="h-[26rem] w-full !items-start"
				sliderClass="h-96 xl:space-x-4 2xl:space-x-6"
			>
				{articles.map((x, k) => (
					<ArticleCard
						key={k}
						data={x}
						session={session}
						intent="carousel"
						favorite={{
							isLoading,
							isUpdating: isFavoriteUpdating,
							isFavorite: data?.blogIds.findIndex((z) => z._id === x._id) !== -1,
							onClick: handleFavoriteClick,
						}}
					/>
				))}
			</Carousel>
		</div>
	);
}
