"use client";

import Carousel, { type ResponsiveType } from "react-multi-carousel";

import type { Service } from "@/types";

import { CustomButtonGroup } from "@/components/carousels";
import ServiceCard from "@/app/components/PopularServices/components/ServiceCard";

import "react-multi-carousel/lib/styles.css";

export const responsive: ResponsiveType = {
	desktop: {
		breakpoint: { min: 1536, max: 3000 },
		items: 4,
		partialVisibilityGutter: 50,
	},
	laptopLarge: {
		breakpoint: { min: 1280, max: 1536 },
		items: 3,
	},
	laptopMedium: {
		breakpoint: { min: 1200, max: 1280 },
		items: 3,
		partialVisibilityGutter: 0,
	},
	laptop: {
		breakpoint: { min: 1024, max: 1199 },
		items: 2,
		partialVisibilityGutter: 75,
	},
	tabletLarge: {
		breakpoint: { min: 930, max: 1023 },
		items: 2,
		partialVisibilityGutter: 50,
	},
	tabletMedium: {
		breakpoint: { min: 880, max: 929 },
		items: 2,
		partialVisibilityGutter: 25,
	},
	tablet: {
		breakpoint: { min: 800, max: 879 },
		items: 2,
		partialVisibilityGutter: 0,
	},
	tabletSmall: {
		breakpoint: { min: 768, max: 799 },
		items: 1,
		partialVisibilityGutter: 300,
	},
	mobileLarge: {
		breakpoint: { min: 690, max: 767 },
		items: 1,
		partialVisibilityGutter: 250,
	},
	mobileMedium: {
		breakpoint: { min: 640, max: 689 },
		items: 1,
		partialVisibilityGutter: 200,
	},
	mobile: {
		breakpoint: { min: 0, max: 639 },
		items: 1,
	},
};

export default function ServicesCarousel({ services }: { services: Service[] }) {
	return (
		<div className="relative grid grid-rows-[22rem_2rem]">
			<Carousel
				responsive={responsive}
				ssr
				partialVisible
				arrows={false}
				showDots={false}
				customButtonGroup={<CustomButtonGroup itemLength={services.length} infinite />}
				renderButtonGroupOutside
				infinite
				keyBoardControl
				autoPlay
				autoPlaySpeed={5000}
				transitionDuration={500}
				customTransition="transform 500ms ease-in-out"
				itemClass="px-2 sm:px-0"
				className="h-80 w-full !items-start"
				sliderClass="h-80 xl:space-x-4 2xl:space-x-6"
			>
				{services.map((service, k) => (
					<ServiceCard key={k} service={service} />
				))}
			</Carousel>
		</div>
	);
}
